import axios from "axios";
import {getAllEvent,} from "../../AllRoutes/ApiRoute";
import {EventSlice} from "../Slice/EventSlice";

export const getAllEventAction = payload => dispatch =>{
    axios.post(getAllEvent, payload).then(res => {
        const {setEvent} = payload

        if (res.data.status === true) {
            setEvent(res.data.data)
            dispatch(EventSlice.actions.getAllNoticeReducers(res.data.data || []))
        }
    }).catch(err => {
        console.log(err)
    });
}