import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Banner1 from "../../assets/images/banner-1.jpg";
import Banner2 from "../../assets/images/banner-2.jpg";
import Banner3 from "../../assets/images/banner-3.jpg";

function BannerComponent(props) {
  return (
    <>
      <Carousel fade>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner1} alt="First slide" />
          <Carousel.Caption>
            <h4>The Best Girl Madrasah Of The West Bengal</h4>
            <h3>
              <span>Khadijatul Qubra Balika Yatimkhana & Madrasah</span>
            </h3>
            {/* <a href="#" className="banner-btn">Admission Now <FaArrowCircleRight/></a> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner2} alt="Second slide" />

          <Carousel.Caption>
            <h4>The Best Girl Madrasah Of West Bengal</h4>
            <h3>
              <span>Khadijatul Qubra Balika Yatimkhana & Madrasah</span>
            </h3>
            {/* <a href="#" className="banner-btn">Admission Now <FaArrowCircleRight/></a> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Banner3} alt="Third slide" />

          <Carousel.Caption>
            <h4>The Best Girl Madrasah Of The West Bengal</h4>
            <h3>
              <span>Khadijatul Qubra Balika Yatimkhana & Madrasah</span>
            </h3>
            {/* <a href="#" className="banner-btn">Admission Now <FaArrowCircleRight/></a> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default BannerComponent;
