import React from "react";
import { Container } from "react-bootstrap";
import MissionComponent from "./MissionAndVissionSubComponent/MissionComponent";
import VissionComponent from "./MissionAndVissionSubComponent/VissionComponent";

function MissionAndVissionComponent(props) {
  return (
    <>
      <section className="innerBodyArea">
        <Container>
          <MissionComponent />
          <VissionComponent />
        </Container>
      </section>
    </>
  );
}

export default MissionAndVissionComponent;
