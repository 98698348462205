// const rootRoute = "http://localhost:1111/"

const rootRoute = "https://www.app-api.kqbym.com/"

export const getTeacher = rootRoute + "unprotected/website/staff/getTeacher"
export const getStaff = rootRoute + "unprotected/website/staff/getGeneralStaff"
export const getAllNotice = rootRoute + "unprotected/website/notice/getAllNotice"
export const getAllEvent = rootRoute + "unprotected/website/event/getAllEvent"
export const getBecomeAMember = rootRoute + "unprotected/website/member/becomeAMember"



