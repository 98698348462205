import React from "react";

function Map(props) {
  return (
    <>
      <section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4541.621071981739!2d88.9041652760069!3d22.61000803159688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a01fde97cb928e3%3A0x2340f9307a47da0a!2sKhadijatul%20Kobra%20(R.D)%20Balika%20Madrasah!5e1!3m2!1sen!2sin!4v1730376270978!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </>
  );
}

export default Map;
