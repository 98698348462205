import { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Regex from "../../Utilities/Regex";
import { getBecomeAMemberAction } from "../../../Redux/Action/BacomeAMemberAction";
import ButtonLoader from "../../ButtonLoader/ButtonLoader";
import CommonLoader from "../../Utilities/CommonLoader";

function BecomeAMemberForm(props) {
  const btnRef = useRef();
  const dispatch = useDispatch();


  // CommonLoader.btn({ btn_ref: btnRef, is_loading: true });

  // hook form
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  // states


  // form Submit
  const onSubmit = async (data) => {
    const payload = {
      data,
      reset,
      btnRef,
    };
   

     dispatch(getBecomeAMemberAction(payload));
  };


  return (
    <>
      <h3>Become a Member</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/*Agent full_name field*/}
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label htmlFor="inputPassword5">
                Member Full Name *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("full_name", {
                  required: true,
                })}
              />
              {/* validation message*/}
              {errors.full_name && (
                <span className="text-danger">
                  {errors.full_name.type === "required" &&
                    "Please Give Member Name"}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label htmlFor="inputPassword5">Guardian Name *</Form.Label>
              <Form.Control
                type="text"
                {...register("guardian_name", {
                  required: true,
                })}
              />
              {/* validation message*/}
              {errors.guardian_name && (
                <span className="text-danger">
                  {errors.guardian_name.type === "required" &&
                    "Please Give Guardian Name"}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label htmlFor="inputPassword5">
                Relation with guardian *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("relation_with_guardian", {
                  required: true,
                })}
              />
              {/* validation message*/}
              {errors.relation_with_guardian && (
                <span className="text-danger">
                  {errors.relation_with_guardian.type === "required" &&
                    "Please Give Relation with guardian"}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">Mobile No. *</Form.Label>
              <Form.Control
                type="tel"
                {...register("mobile", {
                  required: true,
                  pattern:Regex.numeric()
                })}
              />

              {/* validation message*/}
              {errors.mobile && (
                <span className="text-danger">
                  {errors.mobile.type === "required" &&
                    "Please Give Phone Number"}
                    {errors.mobile.type === "pattern" &&
                    "Please Give Valid Phone Number"}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label htmlFor="inputPassword5">Start Year *</Form.Label>
              <Form.Control
                type="text"
                {...register("donation_start_year", {
                  required: true,
                  pattern:Regex.numeric()
                })}
              />
              {/* validation message*/}
              {errors.donation_start_year && (
                <span className="text-danger">
                  {errors.donation_start_year.type === "required" &&
                    "Please Give Start Year"}
                    {errors.donation_start_year.type === "pattern" &&
                    "Please Give Valid Year"}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">
                Donation Amount *
              </Form.Label>
              <Form.Control
                type="text"
                {...register("yearly_donation_amount", {
                  required: true,
                  pattern:Regex.numeric()
                })}
              />

              {/* validation message*/}
              {errors.yearly_donation_amount && (
                <span className="text-danger">
                  {errors.yearly_donation_amount.type === "required" &&
                    "Please Give Donation Amount"}
                    {errors.yearly_donation_amount.type === "pattern" &&
                    "Please Give Valid Amount"}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">Village</Form.Label>
              <Form.Control
                type="text"
                {...register("village", {
                  required: false,
                })}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">Post Office</Form.Label>
              <Form.Control
                type="text"
                {...register("post_office", {
                  required: false,
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">Police Station</Form.Label>
              <Form.Control
                type="text"
                {...register("police_station", {
                  required: false,
                })}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">District</Form.Label>
              <Form.Control
                type="text"
                {...register("district", {
                  required: false,
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">State</Form.Label>
              <Form.Control
                type="text"
                {...register("state", {
                  required: false,
                })}
              />
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label htmlFor="inputPassword5">Pin Code</Form.Label>
              <Form.Control
                type="text"
                {...register("pin_code", {
                  required: false,
                })}
              />
            </Form.Group>
          </Col>
        </Row>

        <Col md={12} sm={12}>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Message "
              {...register("message", {
                required: false,
              })}
            />
          </Form.Group>
        </Col>

        <Button
        
          ref={btnRef}
          variant="primary"
          type="submit"
          className="submit"
        >
          Submit
          {/* <ButtonLoader /> */}
        </Button>
      </form>
    </>
  );
}

export default BecomeAMemberForm;
