import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BecomeAMemberForm from "./BecomeAMemberSubComponent/BecomeAMemberForm";

function BecomeAMemberComponent(props) {
  return (
    <>
      <section className="innerBodyArea">
        <Container>
          <Row className="justify-content-md-center">
            <Col lg="8" md="8" sm="12">
              <div class="formArea">
                <BecomeAMemberForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default BecomeAMemberComponent;
