import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactDetails from "../ContsctDetailsComponent/ContactDetails";
import ContactForm from "./ContactPageSubComponent/ContactForm";
import Map from "./ContactPageSubComponent/Map";

function ContactPageComponent(props) {
  return (
    <>
      <section className="innerBodyArea">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="cd">
                <h3>Contact Details</h3>
                <ContactDetails />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div class="formArea">
                <ContactForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Map />
    </>
  );
}

export default ContactPageComponent;
