import React from "react";
import CommingSoonComponent from "../Component/CommingSoonComponent/CommingSoonComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";

function AdmissionPage(props) {
  return (
    <>
      <HeaderComponent />
      <InnerBannerComponent title="Admission" />
      <CommingSoonComponent />
      <FooterComponent />
    </>
  );
}

export default AdmissionPage;
