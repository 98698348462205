import React, { useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

function ContactForm(props) {
  const btnRef = useRef();
  const dispatch = useDispatch();

  // hook form
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  // states
  const [isNotRobot, setIsNotRobot] = useState(false);

  // form Submit
  const onSubmit = async (data) => {
    const payload = {
      data,
      reset,
      btnRef,
    };

    // dispatch action only when captcha submitted successfully.
    // isNotRobot && dispatch(ContactUsAction(payload));
  };

  const onChange = (value) => {
    // console.log("Captcha value:", value);
    setIsNotRobot(true);
  };
  return (
    <>
      <h3>
        Quick <span>Contact</span>
      </h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/*Agent full_name field*/}
        <Row>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="First Name*"
                {...register("first_name", {
                  required: true,
                })}
              />
              {/* validation message*/}
              {errors.first_name && (
                <span className="text-danger">
                  {errors.first_name.type === "required" &&
                    "Please Give First Name"}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col md={6} sm={12}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Last Name*"
                {...register("last_name", {
                  required: true,
                })}
              />
              {/* validation message*/}
              {errors.last_name && (
                <span className="text-danger">
                  {errors.last_name.type === "required" &&
                    "Please Give Last Name"}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Col md={12} sm={12}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Email *"
              {...register("email", {
                required: true,
              })}
            />
            {/* validation message*/}
            {errors.email && (
              <span className="text-danger">
                {errors.email.type === "required" &&
                  "Please Give Email Address"}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col md={12} sm={12}>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Control
              type="tel"
              placeholder="Phone *"
              {...register("phone", {
                required: true,
              })}
            />

            {/* validation message*/}
            {errors.phone && (
              <span className="text-danger">
                {errors.phone.type === "required" && "Please Give Phone Number"}
              </span>
            )}
          </Form.Group>
        </Col>

        <Col md={12} sm={12}>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Control
              type="text"
              placeholder="Subject *"
              {...register("subject", {
                required: false,
              })}
            />
          </Form.Group>
        </Col>
        <Col md={12} sm={12}>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Message "
              {...register("message", {
                required: false,
              })}
            />
          </Form.Group>
        </Col>

        <Button
          disabled={!isNotRobot}
          ref={btnRef}
          variant="primary"
          type="submit"
          className="submit"
        >
          Submit
          {/* <ButtonLoader /> */}
        </Button>
      </form>
    </>
  );
}

export default ContactForm;
