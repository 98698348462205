import React from "react";
import { Route, Routes } from "react-router-dom";
import AdmissionPage from "../Pages/AdmissionPage";
import AisjPage from "../Pages/AisjPage";
import BecomeAMemberPage from "../Pages/BecomeAMemberPage";
import BoardPage from "../Pages/BoardPage";
import ContactPage from "../Pages/ContactPage";
import CulturalFestivalPage from "../Pages/CulturalFestivalPage";
import DirectorPage from "../Pages/DirectorPage";
import EducationManagementPage from "../Pages/EducationManagementPage";
import ELibraryPage from "../Pages/eLibraryPage";
import EventDetailsPage from "../Pages/EventDetailsPage";
import ExamPage from "../Pages/ExamPage";
import ExamResultsPage from "../Pages/ExamResultsPage";
import FounderPage from "../Pages/FounderPage";
import GuardianVisitorPage from "../Pages/GuardianVisitorPage";
import HomePage from "../Pages/HomePage";
import MeritAwardPage from "../Pages/MeritAwardPage";
import MissionAndVisionPage from "../Pages/MissionAndVisionPage";
import NoticeDetailsPage from "../Pages/NoticeDetailsPage";
import ReAdmissionPage from "../Pages/ReAdmissionPage";
import StaffPage from "../Pages/StaffPage";
import StaringBodyPage from "../Pages/StaringBodyPage";
import SyllabusPage from "../Pages/SyllabusPage";
import TeacherPage from "../Pages/TeacherPage";
import YearlyFestivalPage from "../Pages/YearlyFestivalPage";
import ThankyouPage from "../Pages/ThankyouPage";

function AppRoute(props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/AISJ" element={<AisjPage />} />
        <Route path="/Founder" element={<FounderPage />} />
        <Route path="/Director" element={<DirectorPage />} />
        <Route path="/MissionAndVision" element={<MissionAndVisionPage />} />
        <Route path="/Staring-body" element={<StaringBodyPage />} />
        <Route
          path="/Education-management"
          element={<EducationManagementPage />}
        />
        <Route path="/Board" element={<BoardPage />} />
        <Route path="/Syllabus" element={<SyllabusPage />} />
        <Route path="/Exam" element={<ExamPage />} />
        <Route path="/Exam-results" element={<ExamResultsPage />} />
        <Route path="/Merit-award" element={<MeritAwardPage />} />
        <Route path="/Guardian-visitor" element={<GuardianVisitorPage />} />
        <Route path="/Admission" element={<AdmissionPage />} />
        <Route path="/Re-admission" element={<ReAdmissionPage />} />
        <Route path="/Teacher" element={<TeacherPage />} />
        <Route path="/Staff" element={<StaffPage />} />
        <Route path="/Yearly-festival" element={<YearlyFestivalPage />} />
        <Route path="/Cultural-festival" element={<CulturalFestivalPage />} />
        <Route path="/eLibrary" element={<ELibraryPage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/NoticeDetails/:id" element={<NoticeDetailsPage />} />
        <Route path="/FestivalDetails/:id" element={<EventDetailsPage />} />
        <Route path="/BecomeAMember" element={<BecomeAMemberPage />} />
        <Route path="/Thankyou" element={<ThankyouPage />} />
      </Routes>
    </>
  );
}

export default AppRoute;
