import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import event from "../../assets/images/event.jpg"
import {Link} from "react-router-dom";
import {getAllNoticeAction} from "../../Redux/Action/NoticeAction";
import {useDispatch} from "react-redux";
import {getAllEventAction} from "../../Redux/Action/EventAction";
import imageBlank from '../../assets/images/image-blank.jpg'
import {EventContext} from "../../Pages/CulturalFestivalPage";

function FestivalComponent({data}) {

    return (
        <>
            <section className="innerBodyArea">
                <Container>
                    <Row>
                        {data.map((data, index)=>(
                            <Col lg={4} md={4} sm={12} className="event">
                                <Card>
                                    <Card.Img variant="top" src={data.image_1 || imageBlank} />
                                    <Card.Body>
                                        <Card.Title>{data.title}</Card.Title>
                                        <Card.Text>
                                            Some quick example text to build on the card title and make up the
                                            bulk of the card's content.
                                        </Card.Text>
                                        <Link onClick={e=>{
                                            localStorage.setItem("singleEvent",JSON.stringify(data || []))
                                        }} to={`/FestivalDetails/${data.id}`}><Button variant="success">View Details</Button></Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default FestivalComponent;