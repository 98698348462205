import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import Banner1 from "../../assets/images/banner-1.jpg";
import Banner2 from "../../assets/images/banner-2.jpg";
import Banner3 from "../../assets/images/banner-3.jpg";

function EventGallery({data}) {

    let image1 = data.image_1;
    let image2 = data.image_2;
    let image3 = data.image_3;
    let image4 = data.image_4;
    let image5 = data.image_5;

    let arr = [image1, image2, image3, image4, image5]

    const newArr = arr.filter((str)=> str != "");
    console.log(newArr)



    return (
        <>


            <Carousel>
                {newArr.map((data, index)=>(
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={newArr[index]}
                            alt=" "
                        />
                        <p>{}</p>
                    </Carousel.Item>
                ))}

                {/*<Carousel.Item>*/}
                {/*    <img*/}
                {/*        className="d-block w-100"*/}
                {/*        src={data.image_2}*/}
                {/*        alt="Second slide"*/}
                {/*    />*/}
                {/*</Carousel.Item>*/}
                {/*<Carousel.Item>*/}
                {/*    <img*/}
                {/*        className="d-block w-100"*/}
                {/*        src={data.image_3}*/}
                {/*        alt="Third slide"*/}
                {/*    />*/}
                {/*</Carousel.Item>*/}
                {/*<Carousel.Item>*/}
                {/*    <img*/}
                {/*        className="d-block w-100"*/}
                {/*        src={data.image_4}*/}
                {/*        alt="Third slide"*/}
                {/*    />*/}
                {/*</Carousel.Item>*/}
                {/*<Carousel.Item>*/}
                {/*    <img*/}
                {/*        className="d-block w-100"*/}
                {/*        src={data.image_5}*/}
                {/*        alt="Third slide"*/}
                {/*    />*/}
                {/*</Carousel.Item>*/}
            </Carousel>
        </>
    );
}

export default EventGallery;