import React, {useContext} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import parse from "html-react-parser";
import {EventContext} from "../../Pages/CulturalFestivalPage";
import EventGallery from "./EventGallery";

function FestivalDetailsComponent(props) {
    let data = localStorage.getItem("singleEvent")
    let detailsEvent = JSON.parse(data)

    return (
        <>
            <section className="innerBodyArea">

                <Container>
                    <Row>
                        <Col className="eventDetails">
                            <h3>{detailsEvent.title}</h3>
                            <div>{parse(detailsEvent.content)}</div>
                        </Col>
                    </Row>

                    <Row className="eventDetails mt-5">
                        <Col lg={6} md={6} sm={12}>
                            <h3>Video</h3>
                            <div className="eventVideo">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2AeTMc5eo_A"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe></div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <h3>Gallery</h3>
                            <div className="eventGallery">
                                <EventGallery data={detailsEvent}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default FestivalDetailsComponent;