import React from "react";
import ContactPageComponent from "../Component/ContactPageComponent/ContactPageComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";

function ContactPage(props) {
  return (
    <>
      <HeaderComponent />
      <InnerBannerComponent title="Contact Us" />
      <ContactPageComponent />
      <FooterComponent />
    </>
  );
}

export default ContactPage;
