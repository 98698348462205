import React from "react";
import BecomeAMemberComponent from "../Component/BecomeAMemberComponent/BecomeAMemberComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";

function BecomeAMemberPage(props) {
  return (
    <>
      <HeaderComponent />
      <InnerBannerComponent title="Become A Member" />
      <BecomeAMemberComponent />
      <FooterComponent />
    </>
  );
}

export default BecomeAMemberPage;
