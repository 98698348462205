// making private property
const _loading_state = Symbol();
const _setState = Symbol();

class CommonLoader {
  constructor() {}

  // button loader handle
  static btn({ btn_ref, is_loading = false }) {
    try {
      // button enable or disable
      btn_ref.current.disabled = is_loading;
      console.log(is_loading);

      if (is_loading)
        // when loading we'll remove display none class
        btn_ref.current.lastElementChild.classList.remove("d-none");
      else btn_ref.current.lastElementChild.classList.add("d-none"); // when loading off we removed display none class
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  // section loader handle
  static section(btnRef) {
    try {
      let elements = btnRef?.current;
      // button loading image view or hide
      elements.classList.toggle("d-none");
    } catch (e) {
      return false;
    }
  }
}

export default CommonLoader;
