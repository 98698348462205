import React from "react";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import MissionVissionComponent from "../Component/MissionAndVissionComponent/MissionAndVissionComponent";

function MissionAndVisionPage(props) {
  return (
    <>
      <HeaderComponent />
      <InnerBannerComponent title="Mission & Vision" />
      <MissionVissionComponent />
      <FooterComponent />
    </>
  );
}

export default MissionAndVisionPage;
