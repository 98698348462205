import React from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import NoticeDetailsComponent from "../Component/WhyChooseUsSectionComponent/NoticeDetailsComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import FestivalDetailsComponent from "../Component/FestivalComponent/FestivalDetailsComponent";

function EventDetailsPage(props) {
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="Festival Details"/>
            <FestivalDetailsComponent/>
            <FooterComponent/>
        </>
    );
}

export default EventDetailsPage;