import axios from "axios";
import {getBecomeAMember} from "../../AllRoutes/ApiRoute";
import {EventSlice} from "../Slice/EventSlice";
import CommonLoader from "../../Component/Utilities/CommonLoader";


export const getBecomeAMemberAction = payload => dispatch =>{
    const { reset, btnRef } = payload;
    
    axios.post(getBecomeAMember, payload.data).then(res => {
        // CommonLoader.btn({ btn_ref: btnRef, is_loading: false });

        console.log(res)
      
        if (res.data.status === true) {
            reset();
            
            
        }
    }).catch(err => {
        console.log(err)
        CommonLoader.btn({ btn_ref: btnRef, is_loading: false });
    });
}