import React from 'react';
import { FaFacebookF,FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
function ContactDetails(props) {
    return (
        <>
            <strong>Address:</strong><br/>
            Dakhin Golaichandi (Shankchura Bazar), Taki, Basirhat, North 24 Parganas, <br/>
            West Bengal, India<br/>
            Pin: 743429 <br/><br/>

            <strong>Phone:</strong><br/>
            +91 7076511548 <br/><br/>

            <strong>Email id :</strong><br/>
            <a href="mailto:machhumee@gmail.com">machhumee@gmail.com </a> <br/><br/>
            <div className="sm">
                <a href="https://www.facebook.com/p/khadijtul-kubra-balika-yeatimkhana-madrasah-100057121913021/?rdid=FPa1hl8dEwsS5nzr&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FTBBBeGZLTnP7SC3E%2F" target="_blank" title="Facebook"><FaFacebookF/></a> <a href="#" title="Twitter"><FaTwitter/></a> <a href="#" title="Instagram"><FaInstagram/></a> <a href="#"title="LinkedIn"><FaLinkedinIn/></a>
            </div>
        </>
    );
}

export default ContactDetails;