import React from 'react';

function ThankyouPage(props) {
    return (
        <div>
           ThankyouPage;
        </div>
    );
}

export default ThankyouPage;