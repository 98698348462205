import React, {useEffect, useState} from 'react';
import HeaderComponent from "../Component/HeaderComponent/HeaderComponent";
import InnerBannerComponent from "../Component/InnerBannerComponent/InnerBannerComponent";
import FooterComponent from "../Component/FooterComponent/FooterComponent";
import CommingSoonComponent from "../Component/CommingSoonComponent/CommingSoonComponent";
import FestivalDetailsComponent from "../Component/FestivalComponent/FestivalDetailsComponent";
import FestivalComponent from "../Component/FestivalComponent/FestivalComponent";
import {useDispatch} from "react-redux";
import {getAllEventAction} from "../Redux/Action/EventAction";

function YearlyFestivalPage(props) {
    const dispatch =useDispatch()
    // State
    const [event,setEvent]=useState([])
    const [singleEvent,setSingleEvent]=useState({})

    // getting data from db lifecycle
    useEffect(()=>{
        // making payload for get data
        const payload = {
            setEvent,
            is_cultural_event:"1"
        }
        // action dispatch
        dispatch(getAllEventAction(payload))

    },[])
    return (
        <>
            <HeaderComponent/>
            <InnerBannerComponent title="Yearly Festival"/>
            <FestivalComponent data={event}/>
            <FooterComponent/>
        </>
    );
}

export default YearlyFestivalPage;